import { pick } from "lodash/object";
import { FileChecksum } from "@rails/activestorage/src/file_checksum";
import { BlobUpload } from "@rails/activestorage/src/blob_upload";
import gql from "graphql-tag";

export function getUpload(obj) {
  return pick(obj, ["key", "signedId", "toDelete"]);
}

export function getUploads(objs) {
  return objs.map(getUpload);
}

export function getNonDeletedUploads(objs) {
  return objs.map(getUpload).filter(function (obj) {
    return !obj.toDelete;
  });
}

function getHeaders(input) {
  const headers = {};
  for (let i = 0; i < input.length; i++) {
    const element = input[i];
    headers[element.name] = element.value;
  }
  return headers;
}

class GraphQLBlobRecord {
  constructor(file, checksum, apollo) {
    this.file = file;

    this.attributes = {
      filename: file.name,
      contentType: file.type || "application/octet-stream",
      byteSize: file.size,
      checksum: checksum,
    };

    this.apollo = apollo;
  }

  create(callback) {
    this.callback = callback;
    this.apollo
      .mutate({
        mutation: gql`
          mutation ($input: CreateDirectUploadInput!) {
            payload: createDirectUpload(input: $input) {
              key
              byteSize
              checksum
              contentType
              directUploadHeaders {
                name
                value
              }
              directUploadUrl
              filename
              signedId
              attachableSgid
            }
          }
        `,
        variables: {
          input: this.attributes,
        },
      })
      .then(({ data: { payload } }) => {
        this.directUploadData = {
          url: payload.directUploadUrl,
          headers: getHeaders(payload.directUploadHeaders),
        };
        delete payload.directUploadUrl;
        delete payload.directUploadHeaders;
        this.payload = payload;
        this.callback(null, payload);
      })
      .catch((error) => {
        this.requestDidError(error);
      });
  }

  requestDidError(error) {
    console.error(error);
    this.callback(
      `Error creating Blob for "${this.file.name}". Error: ${error}`,
    );
  }

  toJSON() {
    return { ...this.payload };
  }
}

let id = 0;

export class GraphQLDirectUpload {
  constructor(file, apollo, delegate) {
    this.id = ++id;
    this.file = file;
    this.apollo = apollo;
    this.delegate = delegate;
  }

  create(callback) {
    FileChecksum.create(this.file, (error, checksum) => {
      if (error) {
        callback(error);
        return;
      }

      const blob = new GraphQLBlobRecord(this.file, checksum, this.apollo);
      notify(
        this.delegate,
        "directUploadWillCreateBlobWithGraphQL",
        blob.apollo,
      );

      blob.create((error) => {
        if (error) {
          callback(error);
        } else {
          const upload = new BlobUpload(blob);
          notify(this.delegate, "directUploadWillStoreFileWithXHR", upload.xhr);
          upload.create((error) => {
            if (error) {
              callback(error);
            } else {
              callback(null, blob.toJSON());
            }
          });
        }
      });
    });
  }
}

function notify(object, methodName, ...messages) {
  if (object && typeof object[methodName] == "function") {
    return object[methodName](...messages);
  }
}
