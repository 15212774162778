<template>
  <el-drawer
    ref="drawer"
    :before-close="handleClose"
    :custom-class="$style.drawer"
    :modal="true"
    :visible.sync="visible"
    destroy-on-close
    size="50%"
    title="Interne Kommentare"
  >
    <el-container
      v-loading="loading"
      :class="$style.container"
      direction="vertical"
    >
      <div v-if="commentableSummary" :class="$style.summary">
        <div :is="commentableSummary" v-bind="commentableSummaryProps" />
      </div>
      <div
        ref="main"
        :class="[$style.main, list.nodes.length === 0 ? $style.empty : null]"
      >
        <div
          v-if="list.nodes.length > 0"
          :class="$style.comments"
          @click="onCommentClick"
        >
          <comment-display
            v-for="comment in list.nodes"
            :key="comment.id"
            ref="comment"
            :comment="comment"
            removable
            @remove="removeCommentAndRefresh(comment)"
          />
        </div>
        <div v-else>
          <el-empty
            description="Noch keine interne Kommentare, schreiben Sie den ersten!"
          >
            <template #image><i class="el-icon-s-comment" /></template>
            <small>
              Interne Kommentare können nur von anderen stja-Mitarbeitenden
              gelesen werden. <br />
              Tipp: Hier können auch Dokumente und Bilder angehängt werden!
            </small>
          </el-empty>
        </div>
      </div>
      <div :class="$style.newComment">
        <el-form ref="form" :model="model" label-position="top">
          <el-form-item
            ref="bodyItem"
            :error="serverErrors.body"
            label="Ihr Kommentar"
            prop="body"
          >
            <comment-editor
              v-model="model.body"
              :custom-class="$style.commentBody"
              autofocus
              placeholder="Kommentar hinzufügen"
            />
          </el-form-item>
          <action-list right>
            <el-tooltip content="Diese Liste neu vom Server laden">
              <el-button
                icon="el-icon-refresh-right"
                circle
                @click="$apollo.queries.list.refetch()"
              />
            </el-tooltip>
            <span class="u-flex-spacer" />
            <el-dropdown
              split-button
              type="primary"
              @click="addCommentAndClose"
              @command="executeCommand"
            >
              Hinzufügen &amp; Schließen
              <template #dropdown>
                <el-dropdown-menu placement="top">
                  <el-dropdown-item :command="['discardAndClose']">
                    Verwerfen &amp; Schließen
                  </el-dropdown-item>
                  <el-dropdown-item :command="['addComment']" divided>
                    Hinzufügen
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </action-list>
        </el-form>
      </div>
    </el-container>
  </el-drawer>
</template>

<script>
import gql from "graphql-tag";
import { CommentFragment, ErrorFragment } from "~/src/graphql/fragments";
import { getEmptyConnection } from "~/src/util/paging";
import CommentDisplay from "~/src/components/comments/CommentDisplay";
import ActionList from "~/src/components/common/utils/ActionList";
import { parseServerErrorsAndShowMessage } from "~/src/util/validation";
import { cloneDeep } from "~/src/util/lang";
import Command from "~/src/components/mixins/Command";
import EmployeeSummary from "~/src/components/employees/EmployeeSummary";
import CommentEditor from "~/src/components/common/richtext/CommentEditor";
import { getMouseIntractableParent } from "~/src/util/dom";
import FamilySummary from "~/src/components/families/FamilySummary";
import FamilyRelationSummary from "~/src/components/families/FamilyRelationSummary";
import ApplicationSummary from "~/src/components/applications/ApplicationSummary";
import PassportSummary from "~/src/components/passports/PassportSummary";
import DocumentSummary from "~/src/components/documents/DocumentSummary";
import VoucherSummary from "~/src/components/vouchers/VoucherSummary";

function getEmpty() {
  return {
    body: "",
  };
}

export default {
  name: "CommentDrawer",
  components: { CommentEditor, ActionList, CommentDisplay },
  mixins: [Command],
  inject: ["openFileDrawer"],
  data() {
    return {
      visible: false,
      loading: 0,
      showAdd: false,
      model: {
        body: "",
      },
      serverErrors: {
        body: "",
      },
      record: {},
      list: getEmptyConnection(),
    };
  },
  computed: {
    commentableSummary() {
      const { record } = this;
      switch (record && record.__typename) {
        case "User":
          if (record.role === "EMPLOYEE") {
            return EmployeeSummary;
          }
          break;
        case "Family":
          return FamilySummary;
        case "Person":
          return FamilyRelationSummary;
        case "Application":
          return ApplicationSummary;
        case "Passport":
          return PassportSummary;
        case "Voucher":
          return VoucherSummary;
        case "Document":
          return DocumentSummary;
      }
      return null;
    },
    commentableSummaryProps() {
      const { record } = this;
      const { commentableSummary } = this;
      switch (commentableSummary) {
        case EmployeeSummary:
          return {
            employee: record,
          };
        case FamilySummary:
          return {
            family: record,
          };
        case FamilyRelationSummary:
          return {
            person: record,
          };
        case ApplicationSummary:
          return {
            application: record,
          };
        case PassportSummary:
          return {
            passport: record,
          };
        case VoucherSummary:
          return {
            voucher: record,
          };
        case DocumentSummary:
          return {
            document: record,
          };
      }
      return {};
    },
  },
  watch: {
    visible(to) {
      if (!to && this.resolve) {
        this.resolve({
          comment: this.persistedComment,
          comments: {
            totalCount: this.list.totalCount,
            nodes: cloneDeep(this.list.nodes),
          },
        });
      }
    },
  },
  apollo: {
    list: {
      loadingKey: "loading",
      fetchPolicy: "no-cache",
      skip: (vm) => !(vm.record && vm.record.__typename),
      update: (data) => cloneDeep(data.asEmployee.commentsFor),
      result(result, key) {
        this.$nextTick(this.scrollToLastComment);
      },
      query: gql`
        query ($id: ID!, $type: String!) {
          asEmployee {
            commentsFor(recordType: $type, recordId: $id) {
              nodes {
                ...CommentFragment
              }
              totalCount
            }
          }
        }
        ${CommentFragment}
      `,
      variables() {
        const { record } = this;
        return {
          type: record.__typename,
          id: record.id,
        };
      },
    },
  },
  methods: {
    openFor(record) {
      this.model = getEmpty();
      this.serverErrors = getEmpty();
      this.record = record;
      this.visible = true;
      this.persistedComment = null;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    handleClose(done) {
      if (this.model.body) {
        this.$confirm(
          "Wirklich schließen? Ein ungespeicherter Kommentar ist vorhanden.",
        )
          .then((_) => {
            done();
          })
          .catch((_) => {});
      } else {
        done();
      }
    },
    close() {
      this.$refs.drawer.closeDrawer();
      this.$nextTick(() => {
        this.list = getEmptyConnection();
        this.record = {};
      });
    },
    addComment() {
      const { record, model } = this;
      if (model.body.trim()) {
        this.persistedComment = null;
        return this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $type: String!, $body: String!) {
                payload: asEmployeeAddComment(
                  input: { recordId: $id, recordType: $type, body: $body }
                ) {
                  errors {
                    ...ErrorFragment
                  }
                  node {
                    ...CommentFragment
                  }
                }
              }
              ${ErrorFragment}
              ${CommentFragment}
            `,
            variables: {
              id: record.id,
              type: record.__typename,
              body: model.body,
            },
          })
          .then(({ data: { payload } }) => {
            if (payload) {
              if (payload.errors && payload.errors.length > 0) {
                this.serverErrors = parseServerErrorsAndShowMessage(
                  payload.errors,
                  ["body"],
                );
                return false;
              } else {
                this.list.nodes.push(payload.node);
                this.list.totalCount += 1;
                this.model = getEmpty();
                this.serverErrors = getEmpty();
                this.persistedComment = payload.node;
                return payload.node;
              }
            }
          })
          .catch(this.$apolloErrorHandle);
      } else {
        this.serverErrors.body = "Bitte oben einen Kommentar schreiben";
        return Promise.reject(false);
      }
    },
    addCommentAndClose() {
      return this.addComment()
        .then(() => {
          this.close();
        })
        .catch(() => {});
    },
    discardAndClose() {
      this.model = getEmpty();
      this.serverErrors = getEmpty();
      this.close();
    },
    scrollToLastComment() {
      const { comment, main } = this.$refs;
      const el = Array.isArray(comment) ? comment[comment.length - 1] : comment;
      if (!el) {
        return;
      }

      main.scrollTo({
        top: Math.max(0, el.$el.offsetTop - main.offsetTop - 50),
        behavior: "smooth",
      });
    },
    removeCommentAndRefresh(comment) {
      return this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: ID!) {
              payload: asEmployeeRemoveComment(input: { id: $id })
            }
          `,
          variables: {
            id: comment.id,
          },
        })
        .then(({ data: { payload } }) => {
          if (payload) {
            this.persistedComment = comment;
            return this.$apollo.queries.list.refetch();
          }
        })
        .catch(this.$apolloErrorHandle);
    },
    onCommentClick(e) {
      const interactable = getMouseIntractableParent(e.target);
      if (interactable && interactable.dataset.lightbox) {
        e.preventDefault();
        this.openFileDrawer(JSON.parse(interactable.dataset.lightbox));
      }
    },
  },
};
</script>

<style lang="module.css" module>
.drawer {
  max-width: 700px;
  min-width: 300px;
}

.drawer :global(.el-drawer__body) {
  display: flex;
  flex-direction: column;
}

.container {
  height: calc(100vh - 77px);
}

.summary {
  padding: 0 20px;
}

.main {
  flex: 1;
  overflow: auto;
  padding: 20px;
}

.comments {
}

.empty {
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty i {
  display: block;
  font-size: 58px;
  text-align: center;
  margin-bottom: 10px;
}

.newComment {
  padding: 0 20px 20px;
}

.commentBody {
  max-height: 42vh;
  overflow: auto;
}
</style>
