export default {
  name: "Command",
  provide() {
    return {
      parentCommandExecutor: this,
    };
  },
  inject: { parentCommandExecutor: { default: null } },
  methods: {
    executeCommand([action, ...args] = []) {
      if (action === "noop") {
        return;
      }
      if (typeof this[action] === "function") {
        this[action](...args);
      } else if (this.parentCommandExecutor) {
        this.parentCommandExecutor.executeCommand([action, ...args]);
      } else {
        console.error(
          "executeCommand called with unknown action",
          action,
          args,
        );
      }
    },
  },
};
