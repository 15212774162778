<template>
  <summary-chrome
    :size="size"
    :to="to"
    thing="Mitarbeiter*in"
    icon="el-icon-user"
  >
    <template #meta><user-status :status="employee.status" /></template>
    <template #name
      ><span>{{ employee.name }}</span></template
    >
    <template #actions>
      <slot />
    </template>
  </summary-chrome>
</template>

<script>
import UserStatus from "~/src/components/users/UserStatus";
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";

export default {
  name: "EmployeeSummary",
  components: { SummaryChrome, UserStatus },
  mixins: [SummaryMixin],
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
};
</script>
