<template>
  <router-link
    v-slot="{ href, route, navigate, isActive, isExactActive }"
    :to="to"
    custom
  >
    <el-menu-item
      class="has-link"
      :class="(onlyExact ? isExactActive : isActive) ? 'is-active' : null"
      :index="route.name"
    >
      <a
        :href="href"
        @click.stop="
          $emit('navigated');
          navigate($event);
        "
        ><slot
      /></a>
    </el-menu-item>
  </router-link>
</template>

<script>
export default {
  name: "MenuLink",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    onlyExact: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["navigated"],
};
</script>
