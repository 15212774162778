<template>
  <summary-chrome :size="size" :to="to" :thing="thing" icon="el-icon-user">
    <template #meta
      ><span>{{ person.identifier }}</span></template
    >
    <template v-if="name" #name
      ><span>{{ name }}</span></template
    >
    <template v-else #name><em>Leer</em></template>
    <template #actions>
      <slot />
    </template>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";
import { RELATION_IS } from "~/src/util/family";

export default {
  name: "FamilyRelationSummary",
  components: { SummaryChrome },
  mixins: [SummaryMixin],
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    is() {
      const { person } = this;
      return person.is;
    },
    thing() {
      const { is } = this;
      return RELATION_IS[is] || "Person";
    },
    name() {
      const { person } = this;

      if (person.id) {
        return `${person.firstName} ${person.lastName}`;
      }
      return "";
    },
  },
};
</script>
