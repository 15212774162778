<template>
  <summary-chrome :size="size" :to="to" thing="Antrag" icon="el-icon-s-order">
    <template #meta
      ><span
        >{{ application.identifier }}
        <application-status size="mini" :status="application.status" />
        <application-items-summary :application="application" /></span
    ></template>
    <template #name
      ><span>
        {{ name }}
        <locale-date
          v-if="application.createdAt"
          :date="application.createdAt"
        /> </span
    ></template>
    <template #actions>
      <slot />
    </template>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";
import ApplicationItemsSummary from "~/src/components/applications/ApplicationItemsSummary";
import LocaleDate from "~/src/components/common/i18n/LocaleDate";
import ApplicationStatus from "~/src/components/applications/ApplicationStatus";

export default {
  name: "ApplicationSummary",
  components: {
    ApplicationStatus,
    LocaleDate,
    ApplicationItemsSummary,
    SummaryChrome,
  },
  mixins: [SummaryMixin],
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      const { application } = this;
      if (application && application.applicant) {
        return application.applicant.lastName;
      }
      return "";
    },
  },
};
</script>
