<template>
  <summary-chrome :size="size" :to="to" :thing="thing" :icon="icon">
    <template #meta
      ><span>
        <el-tag v-if="passport" size="mini" type="info">
          {{ passportShortType }} {{ passport.passportNumber }}
        </el-tag>
      </span></template
    >
    <template #name
      ><span>{{ voucher.voucherNumber }}</span></template
    >
    <template #actions>
      <slot />
    </template>
  </summary-chrome>
</template>

<script>
import SummaryChrome from "~/src/components/common/utils/SummaryChrome";
import SummaryMixin from "~/src/components/mixins/SummaryMixin";
import {
  getShortPassType,
  getShortVoucherType,
  getVoucherIcon,
} from "~/src/util/passport";

export default {
  name: "VoucherSummary",
  components: { SummaryChrome },
  mixins: [SummaryMixin],
  props: {
    passport: {
      type: Object,
      required: false,
      default: null,
    },
    voucher: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thing() {
      const { voucher } = this;
      if (voucher) {
        return getShortVoucherType(voucher.voucherType);
      }
      return "";
    },
    icon() {
      const { voucher } = this;
      if (voucher) {
        return getVoucherIcon(voucher);
      }
      return null;
    },
    passportShortType() {
      const { passport } = this;
      if (passport) {
        return getShortPassType(passport.passportType);
      }
      return "";
    },
  },
};
</script>
