import { URLS } from "~/src/util/rails.js.erb";
import { defaultOverlayModal } from "~/src/overlays/base";
import Vue from "vue";

const BASE_URI = URLS.printer_server;
const LOCAL_STORAGE_KEY = "printer/type2Config/v3";

export const TYPE_A4 = "a4";
export const TYPE_PASSPORT = "passport";
export const TYPE_VOUCHER = "voucher";
export const TYPE_HUMAN = {
  [TYPE_A4]: "A4",
  [TYPE_PASSPORT]: "Pass",
  [TYPE_VOUCHER]: "Gutschein",
};

function load() {
  const d = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (d && typeof d === "object") {
    return d;
  } else {
    return {};
  }
}

function save(type2Config) {
  if (type2Config && typeof type2Config === "object") {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(type2Config));
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}

let type2Config = load();

export const ReactiveQueue = new Vue({
  data() {
    return {
      queue: [],
    };
  },
});

// noinspection JSUnusedLocalSymbols
function fakePrintOne(job) {
  job.printing = true;
  console.log("fakePrintOne", job);
  console.log(
    `send ${job.blob.type} (${job.blob.size} bytes, copies: ${job.copies}) to ${job.config.name} (${job.config.paperName}, ${job.config.url})`,
  );
  setTimeout(function () {
    jobError(job, new Error("Fake!"));
  }, 4000);
}

function printOne(job) {
  const body = new FormData();
  body.append("file", job.blob, "file.pdf");
  body.append("printer", job.config.name);
  body.append("copies", "" + job.copies);
  body.append("paperName", job.config.paperName);
  job.printing = true;
  console.log(
    `send ${job.blob.type} (${job.blob.size} bytes, copies: ${job.copies}) to ${job.config.name} (${job.config.paperName}, ${job.config.url})`,
    job,
  );
  fetch(job.config.url, {
    contentType: "multipart/form-data",
    method: "POST",
    body: body,
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      jobResponse(job, response);
    })
    .catch((e) => {
      console.error(e);
      jobError(job, e);
    });
}

function jobResponse(job, response) {
  const index = ReactiveQueue.queue.indexOf(job);
  if (index > -1) {
    ReactiveQueue.queue.splice(index, 1);
  }
  delete job.blob;
  job.resolve({
    ...response,
    job,
  });
}
function jobError(job, e) {
  console.log("jobError", job, e);
  jobResponse(job, {
    success: false,
    exception: e,
    error: `${e}`,
  });
}

function maybeRunNextJob() {
  for (let i = 0; i < ReactiveQueue.queue.length; i++) {
    if (!ReactiveQueue.queue[i].printing) {
      // fakePrintOne(ReactiveQueue.queue[i]);
      printOne(ReactiveQueue.queue[i]);
      return;
    }
  }
}

function addJob(job) {
  console.log("addJob", job, job.blob);
  ReactiveQueue.queue.push(job);
  maybeRunNextJob();
}

export function web2Print(type, blob, copies = 1) {
  const job = {
    type,
    blob,
    copies,
    config: type2Config[type] || {},
    printing: false,
  };
  return new Promise((resolve) => {
    job.resolve = resolve;
    if (!type2Config[type]) {
      showPrinterSetup(
        `${TYPE_HUMAN[type]} Drucker auswählen zum Fortfahren`,
      ).then(() => {
        if (!type2Config[type]) {
          jobError(job, new Error("Kein Drucker ausgewählt"));
        } else {
          addJob(job);
        }
      });
    } else {
      addJob(job);
    }
  });
}

export function showPrinterSetup(reason = null) {
  return defaultOverlayModal(
    import("~/src/components/common/utils/PrinterSetup"),
    {
      reason: reason,
      base: BASE_URI,
      type2Config: type2Config,
    },
  ).then((modalResult) => {
    if (modalResult) {
      type2Config = modalResult;
      save(type2Config);
      return true;
    }
    return false;
  });
}
